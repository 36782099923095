import { Outlet } from "react-router-dom";

import Header from "../components/Header";
import Footer from "../components/Footer";

export default function AppLayout() {
  window.history.pushState({ page: 1 }, "", "");

  window.onpopstate = function (event) {
    if (event) {
      window.location.replace("https://es-bonus.drmarciofhelipe.com/");
    }
  };
  return (
    <>
      <Header />

      <main>
        <Outlet />
      </main>

      <Footer />
    </>
  );
}
